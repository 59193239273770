import { useFormik } from 'formik'
import React, { FC, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

import {
  selectCompanyTabs,
  selectSelectedCompany,
  setEditTabs,
} from '../../../slice/companySlice'
import { Department } from '../../../types/Department'
import { CompanyHeader } from './CompanyHeader'
import UtilsStyles from '../styles/Utils.module.scss'
import { FormField } from './FormField'
import styles from '../styles/Accordion.module.scss'
import ArrowUp from '../../../assets/img/arrowUp.svg'
import ArrowDown from '../../../assets/img/arrowDown.svg'
import { DepartmentService } from '../../../services/DepartmentService'
import { companyDepartmentValidationSchema } from '../helpers/CompanyCardValidation'
import { useHistory } from 'react-router'

type Props = {
  shouldEdit?: boolean
  title: string
}

export type DepartmentInitialType = {
  id: number
  name: string
  address: string
  companyId: string
  contacts: string
  latitude: string
  longitude: string
  allowRadius: string
  sendPost: boolean
}

export const CompanyDepartmentCard: FC<Props> = () => {
  const selectActiveTab = useAppSelector(selectCompanyTabs)
  const dispatch = useAppDispatch()
  const selectedCompany = useAppSelector(selectSelectedCompany)
  //@ts-ignore
  const { departments, licenses } = selectedCompany
  const selectedItem = selectActiveTab.find((tab) => tab.selected)
  const isEdit = selectedItem?.isEdit || false

  const history = useHistory()
  const initialValues: DepartmentInitialType[] =
    departments?.map((el: Department) => {
      // console.log('el', el)
      return {
        id: el.id,
        name: el.name || '',
        address: el.address || '',
        companyId: '',
        contacts: '',
        latitude: el.latitude || '',
        longitude: el.longitude || '',
        allowRadius: el.allow_radius || '',
      }
    }) || []

  const formik = useFormik({
    initialValues,
    validationSchema: companyDepartmentValidationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(
        setEditTabs({
          id: selectedItem!.id,
          value: false,
        })
      )

      values.forEach((el: DepartmentInitialType) => {
        const data = new FormData()
        //@ts-ignore
        const formated = el.sendPost
          ? {
              //@ts-ignore
              companyId: selectedCompany.id || 136,
              name: el.name,
              latitude: el.latitude.toString(),
              longitude: el.longitude.toString(),
              allow_radius: el.allowRadius.toString(),
              contacts: [{ phone: '24313123123', email: '131231231@asd.com' }],
              address: el.address,
            }
          : {
              departmentId: el.id,
              companyId: +el.companyId,
              name: el.name,
              latitude: el.latitude,
              longitude: el.longitude,
              allow_radius: el.allowRadius,
              address: el.address,
              contacts: [{ phone: '24313123123', email: '131231231@asd.com' }],
            }

        for (var key in formated) {
          // @ts-ignore
          data.append(key, formated[key])
          //@ts-ignore
          console.log(key, formated[key])
        }
        el.sendPost
          ? DepartmentService().createDepartment(formated)
          : //@ts-ignore
            DepartmentService().updateDepartment(formated)
      })
    },
  })
  const { errors } = formik

  function resetChanges() {
    // eslint-disable-next-line no-restricted-globals
    const reset = confirm('Are you suer?')
    if (reset) {
      formik.handleReset(initialValues)
    }
  }

  function onEdit() {
    if (selectedItem) {
      dispatch(
        setEditTabs({
          id: selectedItem?.id,
          value: true,
        })
      )
    }
  }
  const [nothing, updateComponent] = useState(true)
  function addNew() {
    formik.values.push({
      //@ts-ignore
      id: Math.random() * 1000,
      name: '',
      address: '',
      companyId: '',
      contacts: '',
      latitude: '',
      longitude: '',
      allowRadius: '',
      sendPost: true,
    })
    updateComponent(!nothing)
  }

  const RenderDepartments = (department: Department, someId = 0) => {
    return (
      <AccordionItem key={department.id} uuid={department.id}>
        <AccordionItemHeading className={styles.accordion}>
          <AccordionItemButton className={styles.accordionBtn}>
            <div className={styles.accordionBtn_title}>
              <p className="body-text-3_b">Підрозділ</p>
              &nbsp;
              <p className="body-text-3_i">{department.id}</p>
              {/* <p className="body-text-3_i">{someId}</p> */}
            </div>
            <AccordionItemState>
              {({ expanded }) => (
                <img alt="btn" src={expanded ? ArrowDown : ArrowUp} />
              )}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FormField
            isEdit={isEdit}
            error={errors[someId]?.name}
            //@ts-ignore
            formik={formik}
            valueKey={formik.values[someId].name}
            textAreaStyle={{
              resize: isEdit ? 'vertical' : 'none',
            }}
            tag="textarea"
            title="Повна назва підрозділу"
            name={`${someId}.name`}
          />
          <FormField
            isEdit={isEdit}
            error={errors[someId]?.address}
            //@ts-ignore
            formik={formik}
            valueKey={formik.values[someId].address}
            textAreaStyle={{
              resize: isEdit ? 'vertical' : 'none',
            }}
            tag="textarea"
            title="адреса підрозділу (адреса використання програмного продукту)"
            name={`${someId}.address`}
          />
          <div className="info-card__flex-filed-wrapper">
            <FormField
              isEdit={isEdit}
              error={errors[someId]?.latitude}
              //@ts-ignore
              formik={formik}
              valueKey={formik.values[someId].latitude}
              tag="input"
              title="широта (latitude)"
              name={`${someId}.latitude`}
              type="number"
            />
            <FormField
              isEdit={isEdit}
              error={errors[someId]?.longitude}
              //@ts-ignore
              formik={formik}
              valueKey={formik.values[someId].longitude}
              tag="input"
              title="долгота (longitude)"
              name={`${someId}.longitude`}
              type="number"
            />
            <FormField
              isEdit={isEdit}
              error={errors[someId]?.allowRadius}
              //@ts-ignore
              formik={formik}
              valueKey={formik.values[someId].allowRadius}
              tag="input"
              title="радиус действия, м (allow radius)"
              name={`${someId}.allowRadius`}
              type="number"
            />
          </div>
          <div className="line" />
        </AccordionItemPanel>
      </AccordionItem>
    )
  }

  // console.log('formik.values', formik.values.length, formik.values)
  return (
    <div className="shadow-container">
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        //@ts-ignore
        preExpanded={[history.location?.state?.departmentId]}
      >
        <CompanyHeader
          onCancel={resetChanges}
          onEdit={onEdit}
          onSave={formik.submitForm}
        />
        <form
          onSubmit={formik.handleSubmit}
          className={UtilsStyles['form-container']}
          method="post"
        >
          <div className={styles.subHeader}>
            <div className={styles.subHeader__btn}>
              <p className="body-text-3_d">Підрозділів:&nbsp;</p>
              <p className="body-text-3_i">{departments?.length || 0}&nbsp;</p>
              <p className="body-text-3_d">| Ліцензій:&nbsp;</p>
              <p className="body-text-3_i">{licenses?.length || 0}</p>
            </div>
            {isEdit && (
              <div className={styles.subHeader__btn}>
                <p className="body-text-3_i">Додати підрозділ</p>
                &nbsp;
                <button
                  type="button"
                  onClick={() => addNew()}
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: 'black',
                    border: 'none',
                  }}
                />
              </div>
            )}
          </div>
          {formik.values.map(
            //@ts-ignore
            (el: Department, someId = 0) => RenderDepartments(el, someId++)
          )}
        </form>
      </Accordion>
    </div>
  )
}
