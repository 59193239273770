import './SidebarList.scss'
import DashboardIcon from '../../../assets/img/home.svg'
import CompanyIcon from '../../../assets/img/company.svg'
import DepartmentsIcon from '../../../assets/img/departments.svg'
import LicenceIcon from '../../../assets/img/licence.svg'
import { SidebarItem } from '../SidebarItem/SidebarItem'
import { SidebarItemType } from '../types'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Button } from '../../Button/Button'
import { useAuthContext } from '../../../Provider/AuthProvider'
import { Accordion } from 'react-accessible-accordion'
import { routeNames } from '../../../constants/routeNames'

const list: SidebarItemType[] = [
  {
    id: 0,
    title: 'Головна',
    img: {
      path: DashboardIcon,
      alt: 'Dashboard',
    },
    path: '/',
    selectable: false,
  },
  {
    id: 1,
    title: 'Компанії',
    subTitle: 'Всі компанії',
    createTitle: 'Додати нову',
    createPath: routeNames['/companies'].create,
    img: {
      path: CompanyIcon,
      alt: 'garbage can icon',
    },
    path: '/companies/page=1',
    selectable: false,
  },
  {
    id: 2,
    title: 'Підрозділи',
    subTitle: 'Всі підрозділи',
    createTitle: 'Додати новий',
    createPath: routeNames['/departments'].create,
    img: {
      path: DepartmentsIcon,
      alt: 'Departments icon',
    },
    path: '/departments/page=1',
    selectable: false,
  },
  {
    id: 3,
    title: 'Ліцензії',
    subTitle: 'Всі ліцензії',
    createTitle: 'Додати нову',
    createPath: routeNames['/licenses'].create,
    img: {
      path: LicenceIcon,
      alt: 'person icon',
    },
    path: '/licenses/page=1',
    selectable: false,
  },
]

type Props = {}

export const SidebarList: FC<Props> = () => {
  const { logoutRequest } = useAuthContext()
  const history = useHistory()
  const [pageList, setPageList] = useState(
    list.map((item) => ({
      ...item,
      selectable: history.location.pathname === item.path,
    }))
  )

  useEffect(() => {
    history.listen(({ pathname }) => {
      setPageList(
        list.map((item) => ({
          ...item,
          selectable: pathname === item.path,
        }))
      )
    })
  }, [history])

  return (
    <div className="sidebar-list">
      <Accordion
        //@ts-ignore
        preExpanded={[]}
        // allowMultipleExpanded
        allowZeroExpanded
      >
        {pageList.map((item) => (
          <SidebarItem item={item} key={item.id} selectable={item.selectable} />
        ))}
        <div
          style={{
            marginTop: 50,
            display: 'flex',
            alignSelf: 'center',
            alignItems: 'center',
            // backgroundColor: 'white',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Button text="Вийти" onClick={logoutRequest} type="danger" />
        </div>
      </Accordion>
    </div>
  )
}
