import React, { useRef } from 'react'
import { useAppDispatch } from '../../../app/hooks'
import { filterCompanyList } from '../../../slice/companySlice'
import styles from '../styles/FilterField.module.scss'
import SearchIcon from '../../../assets/img/search.svg'

export const FilterField = () => {
  const dispatch = useAppDispatch()
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className={styles.inputWrapper}>
      <img
        className={styles.searchIcon}
        src={SearchIcon}
        alt=""
        onClick={() => inputRef.current?.focus()}
      />
      <input
        ref={inputRef}
        className={styles.input}
        type="text"
        name=""
        id=""
        placeholder="Введіть назву або номер телефону..."
        onChange={(e) => {
          console.log('e: ', e.target.value)
          dispatch(filterCompanyList(e.target.value))
        }}
      />
    </div>
  )
}
