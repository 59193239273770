import { useEffect, useState } from 'react'
import { CompanyService } from '../../services/CompanyService'
import { useLocation, useParams } from 'react-router'
import { Company as CompanyType } from '../../types/Company'
import { CompanyCard } from './components/CompanyCard'
import { Company } from './components/Company'
import { useAppDispatch } from '../../app/hooks'
import { setSelectedCompany } from '../../slice/companySlice'

export const EditCompany = () => {
  const params = useParams<{ id: string }>()
  const location = useLocation<CompanyType | undefined>()
  const state = location.state
  const [company, setCompany] = useState(state)
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    loadCompany()
    async function loadCompany() {
      console.log('state', state)
      try {
        setIsLoading(true)
        const result = await CompanyService().loadCompanyById(params.id)
        dispatch(setSelectedCompany(result))
      } catch (err) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }
  }, [state])

  return (
    <div className="page-container">
      {company && (
        <Company
          company={company}
          shouldEdit={true}
          requestType="patch"
          title="Картка компанії"
        />
      )}
      {isLoading && <h1>Loading...</h1>}

      {isError && <h1>Error</h1>}
    </div>
  )
}
