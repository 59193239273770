import React from 'react'
import { Link } from 'react-router-dom'
import { SidebarItemType } from '../types'
import './SidebarItem.scss'
import classNames from 'classnames'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import ArrowUp from '../../../assets/img/arrowUp.svg'
import ArrowDown from '../../../assets/img/arrowDown.svg'

type Props = {
  item: SidebarItemType
  selectable: boolean
}

export const SidebarItem: React.FC<Props> = ({ item, selectable }) => {
  const cx = classNames({
    sidebar__item: true,
    'sidebar__item--selectable': selectable,
  })

  return (
    <AccordionItem key={item.id} uuid={item.id}>
      <Link
        to={item.path}
        style={{
          textDecoration: 'none',
        }}
      >
        <AccordionItemHeading>
          <AccordionItemButton>
            <div className={cx}>
              <img
                className="sidebar__item__img"
                src={item.img.path}
                alt={item.img.alt}
              />
              <p className="sidebar__item__title">{item.title}</p>
              <AccordionItemState>
                {({ expanded }) => {
                  return <img alt="btn" src={expanded ? ArrowDown : ArrowUp} />
                }}
              </AccordionItemState>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel style={{ marginLeft: 55 }}>
          <Link
            className="body-text-3_i"
            style={{ textDecoration: 'none', marginBottom: 8 }}
            to={item.path}
          >
            {item.subTitle}
          </Link>
          <br />
          <Link
            className="body-text-3_i"
            style={{ textDecoration: 'none' }}
            //@ts-ignore
            to={item?.createPath}
          >
            {item.createTitle}
          </Link>
        </AccordionItemPanel>
      </Link>
    </AccordionItem>
  )
}
