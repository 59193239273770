import * as Yup from 'yup'

const validPhone = Yup.string()
  .required('Обов’язкове поле')
  .matches(
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
    'Не вірно введений номер телефону'
  )

export let companyCardValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Обов’язкове поле'),
  edrpo: Yup.string()
    .required('Обов’язкове поле')
    .matches(/^[0-9]+$/, 'Містить лише цифри')
    .min(8, 'ЄДРПОУ складається з 8 цифр')
    .max(8, 'ЄДРПОУ складається з 8 цифр'),
  iban: Yup.string()
    .required('Обов’язкове поле')
    .matches(
      /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/,
      'Не відповідає формату IBAN'
    ),
  bankName: Yup.string().required('Обов’язкове поле'),
  ipn: Yup.string()
    .required('Обов’язкове поле')
    .matches(/^[0-9]+$/, 'Містить лише цифри')
    .min(10, 'IPN складається з 10 цифр')
    .max(10, 'IPN складається з 10 цифр'),

  VATcertificate: Yup.string().required('Обов’язкове поле'),
  contractNumber: Yup.string().trim().required('Обов’язкове поле'),
  contractDate: Yup.date().typeError('Має бути записано у форматі dd.mm.yyyy'),
  companyAddress: Yup.string().required('Обов’язкове поле'),
  companyAddressPhysically: Yup.string().required('Обов’язкове поле'),
  // companyPhone: validPhone,
  // companyEmail: Yup.string().required('Обов’язкове поле').email('Це не email'),
  // contacts: Yup.object({
  //   phone: validPhone,
  //   email: Yup.string().required('Обов’язкове поле').email('Це не email'),
  // }),
  personResponsibleForSigning: Yup.object({
    name: Yup.string().required('Обов’язкове поле'),
    phone: validPhone,
    email: Yup.string().required('Обов’язкове поле').email('Це не email'),
  }),
  personResponsibleForConnectionAndPaymant: Yup.object({
    name: Yup.string().required('Обов’язкове поле'),
    phone: Yup.string()
      .required('Обов’язкове поле')
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        'Не вірно введений номер телефону'
      ),
    email: Yup.string().required('Обов’язкове поле').email('Це не email'),
  }),
})

export let companyDepartmentValidationSchema = Yup.array().of(
  Yup.object().shape({
    name: Yup.string().trim().required('Обов’язкове поле'),
    address: Yup.string().required('Обов’язкове поле'),
    latitude: Yup.number().required('Обов’язкове поле'),
    longitude: Yup.number().required('Обов’язкове поле'),
    allowRadius: Yup.number().required('Обов’язкове поле'),
  })
)
