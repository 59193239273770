import classNames from 'classnames'
import { FormikProps } from 'formik'
import { CSSProperties, FC } from 'react'
import { InitialValues } from './CompanyCard'

type CommuneProps = {
  isEdit: boolean
  formik: FormikProps<InitialValues>
  error?: string
  valueKey: string
  name: string
  title: string
  tagExtraClass?: string[]
}

type InputProps = CommuneProps & {
  type?: string
  tag: 'input'
}

type TextareaProps = CommuneProps & {
  tag: 'textarea'
  rows?: number
  textAreaStyle: CSSProperties
}

type SelectProps = CommuneProps & {
  type?: string
  tag: 'select'
  options: any[]
}

type Props = InputProps | TextareaProps | SelectProps

export const FormField: FC<Props> = (props) => {
  const { tag } = props

  if (tag === 'input') {
    return <InputField {...props} />
  }
  if (tag === 'select') {
    return <SelectField {...props} />
  }
  return <TextareaField {...props} />
}

const InputField: FC<InputProps> = (props) => {
  const {
    isEdit,
    formik,
    error,
    valueKey,
    name = valueKey,
    type = 'text',
    title,
    tagExtraClass,
  } = props

  const inputCx = classNames({
    input: true,
    'body-text-2_b': true,
    'input--disabled': !isEdit,
  })

  const inputLabel = 'input__label-title '
  return (
    <div className="info-card__field-wrapper">
      <label className="info-card__field">
        <p className={inputLabel}>{title}</p>
        <input
          disabled={!isEdit}
          className={`${inputCx} ${tagExtraClass?.join(' ')} ${
            error && 'input--error'
          }`}
          type={type}
          name={name}
          onChange={formik.handleChange}
          value={valueKey}
        />
        <p className="input__label--error">{error}</p>
      </label>
    </div>
  )
}

const TextareaField: FC<TextareaProps> = (props) => {
  const {
    isEdit,
    formik,
    error,
    valueKey,
    name = valueKey,
    rows = 1,
    title,
    tagExtraClass,
  } = props

  const inputCx = classNames({
    input: true,
    'body-text-2_b': true,
    'input--disabled': !isEdit,
  })

  const inputLabel = 'input__label-title '

  return (
    <div className="info-card__field-wrapper">
      <label className="info-card__field">
        <p className={inputLabel}>{title}</p>

        <textarea
          disabled={!isEdit}
          style={{
            resize: isEdit ? 'vertical' : 'none',
          }}
          className={`${inputCx} ${tagExtraClass?.join(' ')} ${
            error && 'input--error'
          }`}
          rows={rows}
          name={name}
          onChange={formik.handleChange}
          value={
            // @ts-ignore
            // formik.values[valueKey]
            valueKey
          }
        />

        <p className="input__label--error">{error}</p>
      </label>
    </div>
  )
}

const SelectField: FC<SelectProps> = (props) => {
  const {
    isEdit,
    formik,
    error,
    valueKey,
    name = valueKey,
    // type = 'text',
    title,
    tagExtraClass,
    options,
  } = props
  const inputCx = classNames({
    input: true,
    'body-text-2_b': true,
    'input--disabled': !isEdit,
  })

  const inputLabel = 'input__label-title '
  return (
    <div className="info-card__field-wrapper">
      <label className="info-card__field">
        <p className={inputLabel}>{title}</p>
        <select
          className={`${inputCx} ${tagExtraClass?.join(' ')} ${
            error && 'input--error'
          }`}
          name={name}
          disabled={!isEdit}
          onChange={formik.handleChange}
          value={valueKey}
        >
          {options?.map((el: any) => (
            <option key={el.id} value={el.id}>
              {el.name}
            </option>
          ))}
        </select>
        <p className="input__label--error">{error}</p>
      </label>
    </div>
  )
}
