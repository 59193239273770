type routeNamesTypes = {
  route: string
  title: string
  genitive: string
  create: string
  chooseTitle?: string
}

const companies: routeNamesTypes = {
  route: '/companies',
  title: 'Компанії',
  genitive: 'Компаній',
  create: '/create-company',
  chooseTitle: 'Оберіть компанію',
}

const departments: routeNamesTypes = {
  route: '/departments',
  title: 'Підрозділи',
  genitive: 'Підрозділів',
  create: '/choose/companies/page=1',
  chooseTitle: 'Оберіть підрозділ',
}

const licenses: routeNamesTypes = {
  route: '/licenses',
  title: 'Ліцензії',
  genitive: 'Ліцензій',
  create: '/choose/departments/page=1',
  chooseTitle: 'Оберіть ліцензію',
}

export const routeNames = {
  '/companies': companies,
  '/departments': departments,
  '/licenses': licenses,
}
