import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Table } from '../../../components/Table/Table'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CompanyService } from '../../../services/CompanyService'
import {
  selectCompanyList,
  selectCompanyTab,
  setSelectedCompany,
} from '../../../slice/companySlice'
import { Company } from '../../../types/Company'

type Props = {}

export const CompanyList: FC<Props> = () => {
  const companyList = useAppSelector(selectCompanyList)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const isChoosing = history.location.pathname.includes('/choose')

  async function pushCompany(company: Company) {
    const { id } = company
    const loadedCompany = await CompanyService().loadCompanyById(id)
    if (isChoosing) {
      dispatch(selectCompanyTab(2))
    } else {
      dispatch(selectCompanyTab(1))
    }
    dispatch(setSelectedCompany(loadedCompany))
    history.push({
      pathname: `/edit-company/${id}`,
      state: {
        ...company,
      },
    })
  }

  return (
    <Table>
      <tbody>
        <tr
          style={{
            textTransform: 'uppercase',
          }}
        >
          <th>Назва</th>
          <th>Адреса</th>
          <th>Телефон</th>
          <th>Підрозділи</th>
          <th>Ліцензії</th>
        </tr>

        {companyList.map((company) => {
          const {
            name,
            address,
            id,
            //@ts-ignore
            departments,
            //@ts-ignore
            licenses,
            contacts,
          } = company
          const phone = JSON.parse(contacts || '[]').map(
            (el: any) => `${el.phone}\n`
          )
          return (
            <tr
              key={id}
              className="table-row"
              onClick={() => pushCompany(company)}
            >
              <td className="table-item table-item--bold">{name}</td>
              <td className="table-item">{address}</td>
              <td className="table-item table-item--link">{phone}</td>
              <td className="table-item">{departments?.length}</td>
              <td className="table-item">{licenses?.length}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
