import { useFormik } from 'formik'
import React, { FC, useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  selectCompanyTabs,
  selectSelectedCompany,
  setEditTabs,
} from '../../../slice/companySlice'
import { CompanyHeader } from './CompanyHeader'
import styles from '../styles/Accordion.module.scss'
import UtilsStyles from '../styles/Utils.module.scss'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import { FormField } from './FormField'
import ArrowUp from '../../../assets/img/arrowUp.svg'
import ArrowDown from '../../../assets/img/arrowDown.svg'
import { useHistory } from 'react-router'
import { LicenseService } from '../../../services/LicenseService'

type Props = {
  shouldEdit?: boolean
  title: string
}

export type licenseInitialType = {
  id: number
  department: string
  phone: string
  email: string
  in_charge_person: string
  department_id: string
  user_id?: number
}

export const CompanyLicenceCard: FC<Props> = () => {
  const selectActiveTab = useAppSelector(selectCompanyTabs)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const selectedCompany = useAppSelector(selectSelectedCompany)

  const selectedItem = selectActiveTab.find((tab) => tab.selected)
  const isEdit = selectedItem?.isEdit || false

  //@ts-ignore
  const { departments } = selectedCompany

  const licenses = departments
    ?.map((department: any) => department.employees)
    .flat()

  const initialValues: licenseInitialType[] = licenses?.map(
    (el: licenseInitialType) => {
      return {
        id: el.id,
        departments: el.department,
        department_id: el.department_id,
        phone: el.phone,
        email: el.email,
        in_charge_person: el.in_charge_person,
        user_id: el.user_id,
      }
    }
  )

  const formik = useFormik({
    //@ts-ignore
    initialValues,
    //   validationSchema: schema,
    validateOnChange: false,
    onSubmit: async (values) => {
      console.log(values)

      dispatch(
        setEditTabs({
          id: selectedItem!.id,
          value: false,
        })
      )

      values.forEach(async (el: licenseInitialType) => {
        const data = {
          //@ts-ignore
          userId: el.user_id,
          //@ts-ignore
          email: el.email,
          //@ts-ignore
          inChargePerson: el.in_charge_person,
          //@ts-ignore
          departmentId: el.department_id,
        }

        await LicenseService().createEmployee(data)
      })
    },
  })
  const { errors } = formik

  function resetChanges() {
    // eslint-disable-next-line no-restricted-globals
    const reset = confirm('Are you suer?')
    if (reset) {
      formik.handleReset(initialValues)
    }
  }

  function onEdit() {
    if (selectedItem) {
      dispatch(
        setEditTabs({
          id: selectedItem?.id,
          value: true,
        })
      )
    }
  }

  const [nothing, updateComponent] = useState(true)
  function addNew() {
    formik.values.push({
      id: formik.values.length + 1,
      department: '',
      phone: '',
      email: '',
      in_charge_person: '',
      department_id: '0',
    })
    updateComponent(!nothing)
  }

  const RenderLicenses = (license: licenseInitialType, someId = 0) => {
    const findOrCreateUser =
      //  useCallback(async
      async () => {
        let user: any = await LicenseService().getUserByLogin(
          formik.values[someId].phone
        )
        if (!user.length) {
          user = await LicenseService().storeNewUser(
            formik.values[someId].phone
          )
        }
        user = user[0] || user
        console.log('user', user)
        const { id: userId } = user
        formik.values[someId].user_id = userId
      }
    // , [formik.values[someId].phone])
    return (
      <AccordionItem key={license.id} uuid={license.id}>
        <AccordionItemHeading className={styles.accordion}>
          <AccordionItemButton className={styles.accordionBtn}>
            <div className={styles.accordionBtn_title}>
              <p className="body-text-3_c">Ліцензія</p>
              &nbsp;
              <p className="body-text-3_i">{license.id}</p>
            </div>
            <AccordionItemState>
              {({ expanded }) => (
                <img alt="btn" src={expanded ? ArrowDown : ArrowUp} />
              )}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FormField
            isEdit={isEdit}
            //@ts-ignore
            formik={formik}
            valueKey={formik.values[someId].department_id}
            tag="select"
            title="Підрозділ"
            name={`${someId}.department_id`}
            options={departments}
          />
          <div className="info-card__flex-filed-wrapper">
            <FormField
              isEdit={isEdit}
              // error={errors[someId]?.address}
              //@ts-ignore
              formik={formik}
              valueKey={formik.values[someId].phone}
              textAreaStyle={{
                resize: isEdit ? 'vertical' : 'none',
              }}
              tag="input"
              title="Номер телефону"
              name={`${someId}.phone`}
              onBlur={findOrCreateUser}
            />
            {/* <button type="button" onClick={() => findOrCreateUser()} /> */}
            <FormField
              isEdit={isEdit}
              // error={errors[someId]?.latitude}
              //@ts-ignore
              formik={formik}
              valueKey={formik.values[someId].email}
              tag="input"
              title="Електронна адреса"
              name={`${someId}.email`}
            />
          </div>
          <div className="info-card__flex-filed-wrapper">
            <FormField
              isEdit={isEdit}
              // error={errors[someId]?.longitude}
              //@ts-ignore
              formik={formik}
              valueKey={formik.values[someId].in_charge_person}
              tag="input"
              title="Відповідальна особа"
              name={`${someId}.in_charge_person`}
            />
            <FormField
              // @ts-ignore
              tag="download"
            />
          </div>
          <div className="line" />
        </AccordionItemPanel>
      </AccordionItem>
    )
  }
  console.log('history.location.state', history.location.state)
  return (
    <div className="shadow-container">
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        //@ts-ignore
        preExpanded={[history.location?.state?.licenseId]}
      >
        <CompanyHeader
          onCancel={resetChanges}
          onEdit={onEdit}
          onSave={formik.submitForm}
        />
        <form
          onSubmit={formik.handleSubmit}
          className={UtilsStyles['form-container']}
          // encType="multipart/form-data"
          // method="post"
        >
          <div className={styles.subHeader}>
            <div className={styles.subHeader__btn}>
              <p className="body-text-3_d">Ліцензій:&nbsp;</p>
              {/* <p className="body-text-3_i"> {licenses?.length || 0}</p> */}
              <p className="body-text-3_i"> {formik.values.length || 0}</p>
            </div>
            {isEdit && (
              <div className={styles.subHeader__btn}>
                <p className="body-text-3_i">Додати ліцензію</p>
                &nbsp;
                <button
                  type="button"
                  onClick={() => addNew()}
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: 'black',
                    border: 'none',
                  }}
                />
              </div>
            )}
          </div>
          {formik.values.map(
            //@ts-ignore
            (el: licenseInitialType, someId = 0) => RenderLicenses(el, someId++)
          )}
        </form>
      </Accordion>
    </div>
  )
}
