import classNames from 'classnames'
import { FC } from 'react'
import './Button.scss'

type Props = {
  onClick?: () => void
  text?: string
  leftIcon?: string
  leftIconTitle?: string
  type?: 'danger' | 'success' | 'warn' | 'info' | 'black'
  // color: 'red' | 'green' | 'yellow' | 'blue' | 'turquoise' | 'purple'
}

export const Button: FC<Props> = (props) => {
  const {
    onClick,
    text = '',
    leftIcon,
    leftIconTitle,
    type = 'info',
    children,
  } = props

  const cx = classNames({
    button: true,
    'button--danger': type === 'danger',
    'button--success': type === 'success',
    'button--warn': type === 'warn',
    'button--info': type === 'info',
    'button--black': type === 'black',
  })
  return (
    <div className={cx} onClick={onClick}>
      {children ? (
        children
      ) : (
        <>
          {leftIcon && <img src={leftIcon} alt={leftIconTitle} />}
          <p className="body-text-1">{text}</p>
        </>
      )}
    </div>
  )
}
