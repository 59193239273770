import { NavigationPanelItem } from './components/NavigationPanelItem'
import { useAppSelector } from '../../app/hooks'
import { selectCompanyTabs } from '../../slice/companySlice'

export const NavigationPanel = () => {
  const companyTabs = useAppSelector(selectCompanyTabs)

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gridGap: 24,
          paddingRight: 65,
          paddingLeft: 65,
        }}
      >
        {companyTabs.map((tab) => (
          <NavigationPanelItem {...tab} key={tab.id} />
        ))}
      </div>

      <div
        className="line"
        style={{
          margin: 0,
          marginTop: -1,
        }}
      ></div>
    </div>
  )
}
