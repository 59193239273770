import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'
import { Department, DepartmentTab } from '../types/Department'
import { DepartmentService } from '../services/DepartmentService'

export interface DepartmentInterface {
  departmentList: Department[]
  filteredDepartmentList: Department[]
  departmentListLoading: 'idle' | 'loading' | 'failed'
  departmentTabs: DepartmentTab[]
  selectedDepartment: Department | {}
}

const initialState: DepartmentInterface = {
  departmentList: [],
  filteredDepartmentList: [],
  departmentListLoading: 'idle',
  selectedDepartment: {},
  departmentTabs: [
    {
      id: 1,
      text: 'Загальні',
      title: 'Відомості про компанію',
      selected: true,
      isEdit: false,
    },
    {
      id: 2,
      text: 'Підрозділи',
      title: 'Відомості про компанію',
      selected: false,
      isEdit: false,
    },
    {
      id: 3,
      text: 'Ліцензії',
      title: 'Відомості про компанію',
      selected: false,
      isEdit: false,
    },
  ],
}

export const loadDepartmentList = createAsyncThunk(
  'department/fetchDepartmentList',
  async () => {
    const response: Department[] =
      await DepartmentService().loadDepartmentList()
    return response
  }
)

export const departmentsSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    filterDepartmentList: (state, action: PayloadAction<string>) => {
      state.filteredDepartmentList = state.departmentList.filter(
        () => {}
        // (item) =>
        //   item.contact.includes(action.payload.trim()) ||
        //   item.address?.includes(action.payload.trim()) ||
        //   item.name.includes(action.payload.trim())
      )
    },
    selectDepartmentTab: (state, action: PayloadAction<number>) => {
      state.departmentTabs = state.departmentTabs.map((tab) => {
        if (tab.id === action.payload) {
          return {
            ...tab,
            selected: true,
            isEdit: false,
          }
        }
        return {
          ...tab,
          selected: false,
          isEdit: false,
        }
      })
    },
    setEditTabs: (
      state,
      action: PayloadAction<{ id: number; value: boolean }>
    ) => {
      state.departmentTabs = state.departmentTabs.map((tab) => {
        if (tab.id === action.payload.id) {
          return {
            ...tab,
            isEdit: action.payload.value,
          }
        }
        return {
          ...tab,
          isEdit: false,
        }
      })
    },
    setSelectedDepartment: (state, action: PayloadAction<Department>) => {
      return {
        ...state,
        selectedDepartment: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDepartmentList.pending, (state) => {
      state.departmentListLoading = 'loading'
    })
    builder.addCase(loadDepartmentList.fulfilled, (state, action) => {
      state.departmentListLoading = 'idle'
      state.departmentList = action.payload
      state.filteredDepartmentList = action.payload
    })
    builder.addCase(loadDepartmentList.rejected, (state) => {
      state.departmentListLoading = 'failed'
    })
  },
})

export const {
  filterDepartmentList,
  selectDepartmentTab,
  setEditTabs,
  setSelectedDepartment,
} = departmentsSlice.actions

export const selectDepartmentList = (state: RootState) =>
  state.department.departmentList
export const selectFilteredDepartmentList = (state: RootState) =>
  state.department.filteredDepartmentList
export const selectDepartmentListLoading = (state: RootState) =>
  state.department.departmentListLoading
export const selectDepartmentTabs = (state: RootState) =>
  state.department.departmentTabs
export const selectSelectedDepartment = (state: RootState) =>
  state.department.selectedDepartment

export default departmentsSlice.reducer
