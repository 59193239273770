import { FC } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectCompanyTabs } from '../../../slice/companySlice'
import { CompanyCard } from './CompanyCard'
import { CompanyDepartmentCard } from './CompanyDepartmentCard'
import { CompanyLicenceCard } from './CompanyLicenceCard'
import { Company as CompanyType } from '../../../types/Company'
import { Method } from 'axios'

type Props = {
  company?: CompanyType
  shouldEdit?: boolean
  title: string
  requestType?: Method
}

export const Company: FC<Props> = (props) => {
  const selectActiveTab = useAppSelector(selectCompanyTabs)

  const selectedItem = selectActiveTab.find((tab) => tab.selected)

  return (
    <div className="page-container">
      {selectedItem?.id === 1 ? (
        <CompanyCard {...props} />
      ) : selectedItem?.id === 2 ? (
        <CompanyDepartmentCard {...props} />
      ) : selectedItem?.id === 3 ? (
        <CompanyLicenceCard {...props} />
      ) : null}
    </div>
  )
}
