import axios, { AxiosResponse } from 'axios'
import { AuthService } from './AuthService'

type Props = {
  headers?: {}
}

export const RequestService = (props: Props) => {
  const { headers } = props

  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${AuthService().getUser()}`,
      Accept: 'application/json',
      'x-interpreter-app-type': 'connectoperator',
      'Content-Type': 'application/json',
      ...headers,
    },
  })

  async function prepareResponse<T>(response: AxiosResponse<T>): Promise<T> {
    if (Math.round(response.status / 100) !== 2) {
      throw new Error(
        JSON.stringify({
          status: response.status,
          text: response.statusText,
        })
      )
    }

    try {
      const res = await response?.data
      console.log('response', res)
      return res
    } catch (err) {
      throw err
    }
  }

  // get request
  async function getRequest<T>(url: string) {
    return await prepareResponse<T>(await instance.get(url))
  }

  // post request
  async function postRequest(
    url: string,
    data: any,
    setProgress?: (c: any) => void
  ) {
    let config = {
      onUploadProgress: (progressEvent: any) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        if (setProgress) {
          setProgress(percentCompleted)
        }
      },
    }

    return await prepareResponse(await instance.post(url, data, config))
  }

  // put request
  async function putRequest(
    url: string,
    data: any,
    setProgress?: (c: any) => void
  ) {
    let config = {
      onUploadProgress: (progressEvent: any) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        if (setProgress) {
          setProgress(percentCompleted)
        }
      },
    }

    return await prepareResponse(await instance.put(url, data, config))
  }

  async function patchRequest(
    url: string,
    data: any,
    setProgress?: (c: any) => void
  ) {
    let config = {
      onUploadProgress: (progressEvent: any) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        if (setProgress) {
          setProgress(percentCompleted)
        }
      },
    }
    return await prepareResponse(await instance.patch(url, data, config))
  }

  // delete request
  async function deleteRequest(url: string, data: any) {
    return await prepareResponse(
      await instance.delete(url, {
        data,
      })
    )
  }

  return {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    patchRequest,
  }
}
