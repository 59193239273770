import { FC } from 'react'
import './Table.scss'

export const Table: FC = ({ children }) => {
  return (
    <div
      style={{
        overflow: 'auto',
        marginTop: 30,
      }}
    >
      <table className="table">{children}</table>
    </div>
  )
}
