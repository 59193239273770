import React, { FC } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { routeNames } from '../../../constants/routeNames'
import { selectCompanyList } from '../../../slice/companySlice'

type Props = {}

export const CountInfo: FC<Props> = () => {
  const companyList = useAppSelector(selectCompanyList)
  const companyCount = companyList.length
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex' }}>
        <p className="body-text-2_d">{routeNames['/companies'].genitive}: </p>
        <p className="body-text-2">{companyCount}</p>
        <p
          className="body-text-2_d"
          style={{
            marginRight: 5,
            marginLeft: 5,
          }}
        >
          {' '}
          |{' '}
        </p>
      </div>
      <div style={{ display: 'flex' }}>
        <p className="body-text-2_d">{routeNames['/departments'].genitive}: </p>
        <p className="body-text-2">coming soon</p>
        <p
          className="body-text-2_d"
          style={{
            marginRight: 5,
            marginLeft: 5,
          }}
        >
          {' '}
          |{' '}
        </p>
      </div>

      <div style={{ display: 'flex' }}>
        <p className="body-text-2_d">{routeNames['/licenses'].genitive}: </p>
        <p className="body-text-2">coming soon</p>
      </div>
    </div>
  )
}
