import { Department } from '../types/Department'
import { RequestService } from './RequestService'

export const DepartmentService = () => {
  const loadDepartmentList = (page: any = 1) => {
    return RequestService({}).getRequest<Department[]>(
      `v2/company/department/all?page=${page}&per_page=7`
    )
  }

  const loadDepartmentById = (id: string) => {
    return RequestService({}).getRequest(`v2/company/department/${id}/edit`)
  }

  const createDepartment = (department: any) => {
    return RequestService({
      headers: {
        // 'Content-Type': 'multipart/form-data',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).postRequest('v2/company/department', department)
  }

  const updateDepartment = (department: Department) => {
    return RequestService({
      // headers: { 'Content-Type': 'multipart/form-data' },
    }).patchRequest('v2/company/department', department)
  }

  const deleteDepartment = (id: number) => {
    return RequestService({}).deleteRequest('v2/company/department', id)
  }

  return {
    createDepartment,
    updateDepartment,
    deleteDepartment,
    loadDepartmentList,
    loadDepartmentById,
  }
}
