import React, { FC } from 'react'
import { Company, CompanyTab } from '../../../types/Company'
import styles from '../NavigationPanel.module.scss'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  selectCompanyTab,
  selectCompanyTabs,
  selectSelectedCompany,
} from '../../../slice/companySlice'

export const NavigationPanelItem: FC<CompanyTab> = ({
  id,
  text,
  selected,
  isEdit,
  // disabled,
}) => {
  const selectActiveTab = useAppSelector(selectCompanyTabs)
  //@ts-ignore
  const selectedCompany: Company = useAppSelector(selectSelectedCompany)
  const dispatch = useAppDispatch()

  const selectedItem = selectActiveTab.find((tab) => tab.selected)

  function handleGoNextTab() {
    if (selectedItem?.isEdit) {
      // eslint-disable-next-line no-restricted-globals
      const res = confirm('У вас є незбережені дані, перейти?')
      if (res) {
        dispatch(selectCompanyTab(id))
      }
      return
    }

    dispatch(selectCompanyTab(id))
  }

  let disabled = false

  //@ts-ignore
  if (id === 2 && !selectedCompany.id) {
    disabled = true
    //@ts-ignore
  } else if (id === 3 && !selectedCompany?.departments?.length) {
    disabled = true
  }

  return (
    <div className={`${styles.navigationPanelItem} `}>
      <button
        style={{ border: 'none', backgroundColor: 'transparent' }}
        onClick={handleGoNextTab}
        disabled={disabled}
      >
        <h4
          className={`${styles.navigationPanelTitle} ${
            selected && styles.navigationPanelTitleSelected
          } ${disabled && styles.navigationPanelTitleDisabled} h4`}
        >
          {text}
        </h4>
      </button>
      <div
        className={`selectable-line ${selected && 'selectable-line--selected'}`}
      ></div>
    </div>
  )
}
