import classNames from 'classnames'
import { useFormik } from 'formik'
import { FC, useState } from 'react'
import { Button } from '../../../components/Button/Button'
import { CompanyService } from '../../../services/CompanyService'
import { Company } from '../../../types/Company'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  selectCompanyTabs,
  selectSelectedCompany,
  setEditTabs,
} from '../../../slice/companySlice'
import { CompanyHeader } from './CompanyHeader'
import { FormField } from './FormField'
import { Documents } from './Documents'
import { companyCardValidationSchema } from '../helpers/CompanyCardValidation'
import UtilsStyles from '../styles/Utils.module.scss'
import moment from 'moment'
import { Method } from 'axios'

type Props = {
  company?: Company
  shouldEdit?: boolean
  title: string
  requestType?: Method
}

export type InitialValues = {
  name: string
  edrpo: string
  iban: string
  bankName: string
  ipn: string
  contractNumber: string
  contractDate: string
  VATcertificate: string
  companyAddress: string
  // companyPhone: string
  // companyEmail: string
  contacts: { phone: any; email: string }[]
  companyAddressPhysically: string

  personResponsibleForSigning: {
    name: string
    phone: string
    email: string
  }
  personResponsibleForConnectionAndPaymant: {
    name: string
    phone: string
    email: string
  }
}

export const CompanyCard: FC<Props> = ({ title, requestType }) => {
  //@ts-ignore
  const company: Company = useAppSelector(selectSelectedCompany)
  console.log('🚀 ~ file: CompanyCard.tsx ~ line 47 ~ company', company)
  const [documentList, setDocumentList] = useState<
    {
      id: string
      file: File
    }[]
  >([])
  const selectActiveTab = useAppSelector(selectCompanyTabs)
  const dispatch = useAppDispatch()

  const selectedItem = selectActiveTab.find((tab) => tab.selected)
  const isEdit = selectedItem?.isEdit || false
  const initialResponsible = (company?.responsible_persons &&
    JSON.parse(company.responsible_persons)) || [
    {
      name: 'mock name',
      phones: [{ phone: '+380971455523', email: 'pupa@lupa.com' }],
    },
    {
      name: 'mock name',
      phones: [{ phone: '+380971455523', email: 'pupa@lupa.com' }],
    },
  ]
  const initialContacts = (company?.contacts &&
    JSON.parse(company?.contacts)) || [
    { phone: '+380971455523', email: 'pupa@lupa.com' },
  ]

  const initialValues: InitialValues = {
    name: company?.name || 'the World',
    edrpo: company?.edrpo || '',
    iban: company?.iban || '',
    bankName: company?.bank_name || 'coming soon',
    ipn: company?.ipn || '',
    contractNumber: company?.contract_number || 'coming soon',
    contractDate:
      moment(company?.contract_date).format('DD.MM.YYYY') || 'coming soon',
    VATcertificate: company?.pdv || 'coming soon',
    companyAddress: company?.legal_address || '',
    contacts: initialContacts,
    companyAddressPhysically: company?.address || 'coming soon',
    personResponsibleForSigning: {
      name: initialResponsible[0]?.name,
      phone: initialResponsible[0]?.phones[0]?.phone,
      email: initialResponsible[0]?.phones[0]?.email,
    },
    personResponsibleForConnectionAndPaymant: {
      name: initialResponsible[1]?.name,
      phone: initialResponsible[1]?.phones[0]?.phone,
      email: initialResponsible[1]?.phones[0]?.email,
    },
    // personResponsibleForSigning: {
    //   name: 'x',
    //   phone: '+380',
    //   email: 'test@gmail.com',
    // },
    // personResponsibleForConnectionAndPaymant: {
    //   name: 'x',
    //   phone: '+380',
    //   email: 'test@gmail.com',
    // },
  }

  const formik = useFormik({
    initialValues,
    validationSchema: companyCardValidationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(
        setEditTabs({
          id: selectedItem!.id,
          value: false,
        })
      )
      let data = new FormData()
      // new FormData(form)
      console.log('values: ', values)
      // CompanyService().createCompany(values);
      const requestData: any = {
        name: values.name,
        address: values.companyAddressPhysically,
        region:
          // values.region ||
          'region mock',
        legalAddress: values.companyAddress,
        iban: values.iban,
        // pdv: values.pdv,
        bankName: values.bankName,
        contractNumber: values.contractNumber,
        contractDate: values.contractDate,
        documents: documentList.map((el) => el.file),
        // documents: documentList,
        contacts: values.contacts,
        responsiblePersons: [
          {
            name: values.personResponsibleForSigning.name,
            phones: [
              {
                phone: values.personResponsibleForSigning.phone,
                email: values.personResponsibleForSigning.email,
              },
            ],
          },
          {
            name: values.personResponsibleForConnectionAndPaymant.name,
            phones: [
              {
                phone: values.personResponsibleForConnectionAndPaymant.phone,
                email: values.personResponsibleForConnectionAndPaymant.email,
              },
            ],
          },
        ],
        ipn: values.ipn,
        edrpo: values.edrpo,
      }

      if (requestType === 'patch') requestData.id = company?.id

      for (var key in requestData) {
        console.log('ITS A KEY', key, 'ADN VALUE IS', requestData[key])
        if (requestData[key].constructor === Array) {
          console.log('its array guys')
          if (key === 'documents') {
            for (let i = 0; i < documentList.length; i++) {
              data.append('documents', documentList[i].file)
            }
          } else data.append(`${key}[]`, JSON.stringify(requestData[key]))
        } else {
          data.append(key, requestData[key])
        }
      }
      if (requestType === 'patch') CompanyService().updateCompany(data)
      if (requestType === 'post') CompanyService().createCompany(data)

      console.log('requestData', requestData)
    },
  })
  const { errors } = formik

  function resetChanges() {
    // eslint-disable-next-line no-restricted-globals
    const reset = confirm('Are you suer?')
    if (reset) {
      formik.handleReset(initialValues)
    }
  }

  function onEdit() {
    if (selectedItem) {
      dispatch(
        setEditTabs({
          id: selectedItem?.id,
          value: true,
        })
      )
    }
  }

  const inputCx = classNames({
    input: true,
    'body-text-2_b': true,
    'input--disabled': !isEdit,
  })

  const inputLabel = 'input__label-title'

  console.log('update: ')

  console.log('err: ', errors)
  return (
    <div className="shadow-container">
      <CompanyHeader
        onCancel={resetChanges}
        onEdit={onEdit}
        onSave={formik.submitForm}
      />

      <form
        onSubmit={formik.handleSubmit}
        className={UtilsStyles['form-container']}
        encType="multipart/form-data"
        method="post"
      >
        <div className="info-card__field-wrapper">
          <label className="info-card__field">
            <p className={inputLabel}>ID компанії:</p>
            <input
              disabled={true}
              style={{
                color: '#117DCE',
              }}
              className={`${inputCx} input--small input--disabled`}
              type="text"
              name="id"
              value={company?.id}
            />
          </label>
        </div>

        <FormField
          isEdit={isEdit}
          formik={formik}
          error={errors.name}
          valueKey={formik.values.name}
          name="name"
          tag="textarea"
          textAreaStyle={{
            resize: isEdit ? 'vertical' : 'none',
          }}
          title="Повна назва підприємства / організації"
        />

        <div className="info-card__flex-filed-wrapper">
          <FormField
            isEdit={isEdit}
            formik={formik}
            error={errors.edrpo}
            valueKey={formik.values.edrpo}
            name="edrpo"
            type="text"
            tag="input"
            tagExtraClass={['input--small']}
            title="Код ЄДРПОУ"
          />

          <FormField
            isEdit={isEdit}
            formik={formik}
            error={errors.iban}
            valueKey={formik.values.iban}
            name="iban"
            type="text"
            tag="input"
            title="IBAN"
          />

          <FormField
            isEdit={isEdit}
            formik={formik}
            error={errors.bankName}
            valueKey={formik.values.bankName}
            name="bankName"
            type="text"
            tag="input"
            title="Найменування банку"
          />
        </div>

        <div className="info-card__flex-filed-wrapper">
          <FormField
            isEdit={isEdit}
            formik={formik}
            error={errors.ipn}
            valueKey={formik.values.ipn}
            name="ipn"
            type="text"
            tag="input"
            title="ІПН"
          />

          <FormField
            isEdit={isEdit}
            formik={formik}
            error={errors.VATcertificate}
            valueKey={formik.values.VATcertificate}
            name="VATcertificate"
            type="text"
            tag="input"
            title="Свідоцтво платника ПДВ"
          />

          <FormField
            isEdit={isEdit}
            formik={formik}
            error={errors.contractNumber}
            valueKey={formik.values.contractNumber}
            name="contractNumber"
            type="text"
            tag="input"
            title="Номер договору"
          />

          <FormField
            isEdit={isEdit}
            formik={formik}
            error={errors.contractDate}
            valueKey={formik.values.contractDate}
            name="contractDate"
            // type="date"
            tag="input"
            title="ДАТА ДОГОВОРУ"
          />
        </div>

        <FormField
          isEdit={isEdit}
          formik={formik}
          error={errors.companyAddress}
          valueKey={formik.values.companyAddress}
          name="companyAddress"
          tag="textarea"
          textAreaStyle={{
            resize: isEdit ? 'vertical' : 'none',
          }}
          title="Юридична адреса підприємства / організації"
        />

        <FormField
          isEdit={isEdit}
          formik={formik}
          error={errors.companyAddressPhysically}
          valueKey={formik.values.companyAddressPhysically}
          name="companyAddressPhysically"
          tag="textarea"
          textAreaStyle={{
            resize: isEdit ? 'vertical' : 'none',
          }}
          title="Фізична адреса підприємства / організації"
        />

        <div className="info-card__flex-filed-wrapper">
          <FormField
            isEdit={isEdit}
            formik={formik}
            // error={errors.contacts[0]?.phone}
            valueKey={formik.values.contacts[0].phone}
            name="contacts[0].phone"
            type="tel"
            tag="input"
            title="Номер телефону"
          />

          <FormField
            isEdit={isEdit}
            formik={formik}
            // error={errors.contacts?.email}
            valueKey={formik.values.contacts[0].email}
            name="contacts[0].email"
            type="email"
            tag="input"
            title="Електронна адреса"
          />
        </div>

        <Documents
          documentList={documentList}
          setDocumentList={setDocumentList}
        />

        <div className="line" />

        <div className="info-card__flex-filed-wrapper">
          <div>
            <FormField
              isEdit={isEdit}
              formik={formik}
              error={errors.personResponsibleForSigning?.name}
              valueKey={formik.values.personResponsibleForSigning.name}
              name="personResponsibleForSigning.name"
              type="text"
              tag="input"
              title="Відповідальна особа За підписання документів"
            />

            <FormField
              isEdit={isEdit}
              formik={formik}
              error={errors.personResponsibleForSigning?.phone}
              valueKey={formik.values.personResponsibleForSigning.phone}
              name="personResponsibleForSigning.phone"
              type="text"
              tag="input"
              title="Номер телефону"
            />

            <FormField
              isEdit={isEdit}
              formik={formik}
              error={errors.personResponsibleForSigning?.email}
              valueKey={formik.values.personResponsibleForSigning.email}
              name="personResponsibleForSigning.email"
              type="text"
              tag="input"
              title="Електронна адреса"
            />
          </div>

          <div>
            <FormField
              isEdit={isEdit}
              formik={formik}
              error={errors.personResponsibleForConnectionAndPaymant?.name}
              valueKey={
                formik.values.personResponsibleForConnectionAndPaymant.name
              }
              name="personResponsibleForConnectionAndPaymant.name"
              type="text"
              tag="input"
              title="Відповідальна особа За підключення / нарахування"
            />

            <FormField
              isEdit={isEdit}
              formik={formik}
              error={errors.personResponsibleForConnectionAndPaymant?.phone}
              valueKey={
                formik.values.personResponsibleForConnectionAndPaymant.phone
              }
              name="personResponsibleForConnectionAndPaymant.phone"
              type="text"
              tag="input"
              title="Номер телефону"
            />

            <FormField
              isEdit={isEdit}
              formik={formik}
              error={errors.personResponsibleForConnectionAndPaymant?.email}
              valueKey={
                formik.values.personResponsibleForConnectionAndPaymant.email
              }
              name="personResponsibleForConnectionAndPaymant.email"
              type="email"
              tag="input"
              title="Електронна адреса"
            />
          </div>
        </div>
      </form>
    </div>
  )
}
