import './App.scss'
import { Confirm } from './components/Modal/Confirm'
import { Navigation } from './navigation/Navigation'
import { AuthPage } from './pages/Auth/Auth'
import { useAuthContext } from './Provider/AuthProvider'

function App() {
  const { isAuthorized } = useAuthContext()
  return (
    <>
      {!!isAuthorized ? <Navigation /> : <AuthPage />}
      <Confirm ref={(ref: unknown) => Confirm.setRef(ref)} />
    </>
  )
}

export default App
