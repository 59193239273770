import { Company } from '../types/Company'
import { RequestService } from './RequestService'

export const CompanyService = () => {
  const loadCompanyList = (page: any = 1) => {
    return RequestService({}).getRequest<Company[]>(
      `v2/company/all?page=${page}&per_page=7`
    )
  }

  const loadCompanyById = (id: any) => {
    return RequestService({}).getRequest(`v2/company/${id}`)
  }

  const createCompany = (company: any) => {
    return RequestService({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).postRequest('v2/company', company)
  }

  // const updateCompany = (company: Company) => {
  //   return RequestService({
  //     headers: { 'Content-Type': 'multipart/form-data' },
  //   }).putRequest('v2/company', company)
  // }

  const updateCompany = (company: any) => {
    return RequestService({
      headers: { 'Content-Type': 'multipart/form-data' },
    }).patchRequest('v2/company', company)
  }

  const deleteCompany = (id: number) => {
    return RequestService({}).deleteRequest('v2/company', id)
  }

  return {
    createCompany,
    updateCompany,
    deleteCompany,
    loadCompanyList,
    loadCompanyById,
  }
}
