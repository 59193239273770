import { License } from '../types/License'
import { RequestService } from './RequestService'

export const LicenseService = () => {
  const loadLicenseList = (page: any = 1) => {
    return RequestService({}).getRequest<License[]>(
      `v2/company/license/all?page=${page}&per_page=7`
    )
  }

  const annulLicense = (id: number | string) => {
    return RequestService({}).patchRequest('v2/company/license/revoke', { id })
  }

  const storeNewUser = (login: string) => {
    return RequestService({}).postRequest('v2/user/store', { login })
  }

  const getUserByLogin = (login: string) => {
    return RequestService({})
      .getRequest(`v2/user/login/${login}/searchByLogin/?user_type[]=employee&user_type[]=client&user_type[]=fucker&type=array
`)
  }

  const createEmployee = (employee: any) => {
    return RequestService({}).putRequest(
      'v2/company/department/employee',
      employee
    )
  }

  const loadUserList = () => {}

  return {
    annulLicense,
    loadLicenseList,
    loadUserList,
    storeNewUser,
    createEmployee,
    getUserByLogin,
  }
}
