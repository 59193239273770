import { License } from './../types/License'
import { Department } from './../types/Department'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'
import { Company, CompanyTab } from '../types/Company'
import { CompanyService } from '../services/CompanyService'

export interface CompanyInterface {
  companyList: Company[]
  departmentList: Department[]
  licenseList: License[]
  filteredCompanyList: Company[]
  companyListLoading: 'idle' | 'loading' | 'failed'
  companyTabs: CompanyTab[]
  selectedCompany: Company | {}
}

const initialState: CompanyInterface = {
  companyList: [],
  departmentList: [],
  licenseList: [],
  filteredCompanyList: [],
  companyListLoading: 'idle',
  selectedCompany: {},
  companyTabs: [
    {
      id: 1,
      text: 'Загальні',
      title: 'Відомості про компанію',
      selected: true,
      isEdit: false,
    },
    {
      id: 2,
      text: 'Підрозділи',
      title: 'Відомості про компанію',
      selected: false,
      isEdit: false,
    },
    {
      id: 3,
      text: 'Ліцензії',
      title: 'Відомості про компанію',
      selected: false,
      isEdit: false,
    },
  ],
}

export const loadCompanyList = createAsyncThunk(
  'company/fetchCompanyList',
  async () => {
    //@ts-ignore
    const { data }: { data: Company[] } =
      await CompanyService().loadCompanyList()
    return data
  }
)

export const companySlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    filterCompanyList: (state, action: PayloadAction<string>) => {
      state.filteredCompanyList = state.companyList.filter(
        (item) =>
          item.contact.includes(action.payload.trim()) ||
          item.address?.includes(action.payload.trim()) ||
          item.name.includes(action.payload.trim())
      )
    },
    selectCompanyTab: (state, action: PayloadAction<number>) => {
      state.companyTabs = state.companyTabs.map((tab) => {
        if (tab.id === action.payload) {
          return {
            ...tab,
            selected: true,
            isEdit: false,
          }
        }
        return {
          ...tab,
          selected: false,
          isEdit: false,
        }
      })
    },
    setEditTabs: (
      state,
      action: PayloadAction<{
        id: number
        value: boolean
      }>
    ) => {
      state.companyTabs = state.companyTabs.map((tab) => {
        if (tab.id === action.payload.id) {
          return {
            ...tab,
            isEdit: action.payload.value,
          }
        }
        return {
          ...tab,
          isEdit: false,
        }
      })
    },
    setSelectedCompany: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedCompany: action.payload,
      }
    },
    setCompanyList: (state, action: PayloadAction<any>) => {
      console.log('sukablyat')
      return { ...state, companyList: action.payload }
    },
    setDepartmentList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        departmentList: action.payload,
      }
    },
    setLicenseList: (state, action: PayloadAction<any>) => {
      return { ...state, licenseList: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCompanyList.pending, (state) => {
      state.companyListLoading = 'loading'
    })
    builder.addCase(loadCompanyList.fulfilled, (state, action) => {
      state.companyListLoading = 'idle'
      state.companyList = action.payload
      state.filteredCompanyList = action.payload
    })
    builder.addCase(loadCompanyList.rejected, (state) => {
      state.companyListLoading = 'failed'
    })
  },
})

export const {
  filterCompanyList,
  selectCompanyTab,
  setEditTabs,
  setSelectedCompany,
  setDepartmentList,
  setCompanyList,
  setLicenseList,
} = companySlice.actions

export const selectCompanyList = (state: RootState) => state.company.companyList
export const selectFilteredCompanyList = (state: RootState) =>
  state.company.filteredCompanyList
export const selectCompanyListLoading = (state: RootState) =>
  state.company.companyListLoading
export const selectCompanyTabs = (state: RootState) => state.company.companyTabs
export const selectSelectedCompany = (state: RootState) =>
  state.company.selectedCompany
export const selectDeparmentList = (state: RootState) =>
  state.company.departmentList
export const selectLicneseList = (state: RootState) => state.company.licenseList

export default companySlice.reducer
