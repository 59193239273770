import { BrowserRouter, Route } from 'react-router-dom'
import { Sidebar } from '../components/Sidebar/Sidebar'
import { Companies } from '../pages/Companies/Companies'
import { CompanyCard } from '../pages/Companies/components/CompanyCard'
import { EditCompany } from '../pages/Companies/EditCompany'
import { Dashboard } from '../pages/Dashboard/Dashboard'
import { CreateCompany } from '../pages/Companies/CreateCompany'
import { Licences } from '../pages/Licences/Licences'
import { Deparments } from '../pages/Departments/Deparments'

export const Navigation = () => {
  return (
    <BrowserRouter>
      <div className="page-with-sidebar">
        <Sidebar />

        <Route path="/" exact={true} component={Dashboard} />

        <Route
          path="/choose/companies/page=:page"
          exact={true}
          component={Companies}
        />

        <Route
          path="/companies/page=:page"
          exact={true}
          component={Companies}
        />
        <Route path="/company-card" exact={true} component={CompanyCard} />
        <Route path="/create-company" exact={true} component={CreateCompany} />
        <Route path="/edit-company/:id" exact={true} component={EditCompany} />

        <Route path="/licenses/page=:page" exact={true} component={Licences} />
        <Route
          path="/departments/page=:page"
          exact={true}
          component={Deparments}
        />
        <Route
          path="/choose/departments/page=:page"
          exact={true}
          component={Deparments}
        />
        {/* <UserProvider>
          <Route path="/users" exact={true} component={Users} />
          <Route path="/edit-user/:id" exact={true} component={EditUser} />
          <Route path="/create-user" exact={true} component={EditUser} />
        </UserProvider> */}
      </div>
    </BrowserRouter>
  )
}
