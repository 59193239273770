import { authType } from '../pages/Auth/Auth'
import { RequestService } from './RequestService'

export const AuthService = () => {
  const authRequest = (creds: authType) =>
    RequestService({}).postRequest('/v2/auth/login/email', creds)

  const logoutRequest = () =>
    RequestService({}).postRequest('/v2/auth/logout', {})

  const setUser = (token: string) => {
    localStorage.setItem('token', JSON.stringify(token))
  }

  const getUser = () => {
    // @ts-ignore
    return JSON.parse(localStorage.getItem('token'))
  }

  return {
    logoutRequest,
    authRequest,
    setUser,
    getUser,
  }
}
