import { Company } from './components/Company'

export const CreateCompany = () => {
  return (
    <Company
      title="Створити нову компанію"
      requestType="post"
      shouldEdit={true}
    />
  )
}
