import React from 'react'
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Table } from '../../components/Table/Table'
import { CompanyService } from '../../services/CompanyService'
import {
  selectCompanyTab,
  selectLicneseList,
  setSelectedCompany,
} from '../../slice/companySlice'
import { License } from '../../types/License'
import { Header } from '../Companies/components/Header'

export const Licences = () => {
  const dispatch = useAppDispatch()
  const licenseList = useAppSelector(selectLicneseList)
  const history = useHistory()

  async function pushCompany({
    companyId,
    licenseId,
  }: {
    companyId: string | number
    licenseId: string | number
  }) {
    const loadedCompany = await CompanyService().loadCompanyById(companyId)
    dispatch(setSelectedCompany(loadedCompany))
    dispatch(selectCompanyTab(3))
    history.push({
      pathname: `/edit-company/${companyId}`,
      state: {
        licenseId: licenseId,
      },
    })
  }

  return (
    <div className="page-container">
      <div className="shadow-container">
        <Header />
        <Table>
          <tbody>
            <tr
              style={{
                textTransform: 'uppercase',
              }}
            >
              <th>телефон</th>
              <th>Адреса підрозділу (використання)</th>
              <th>Компанія</th>
            </tr>

            {licenseList?.map((license: License) => {
              //@ts-ignore
              const { id, employee, phone } = license

              return (
                <tr
                  key={id}
                  className="table-row"
                  onClick={() =>
                    pushCompany({
                      //@ts-ignore
                      companyId: employee.department.company_id,
                      licenseId: id,
                    })
                  }
                >
                  <td className="table-item table-item--link">{phone}</td>
                  <td className="table-item table-item--bold">
                    {employee.department.address}
                  </td>
                  <td className="table-item table-item--bold">
                    {employee.department.name}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
