import { CompanyList } from './components/CompanyList'
import { useAppSelector } from '../../app/hooks'
import { selectCompanyListLoading } from '../../slice/companySlice'
import { Console } from '../../debug/Console'
import { Header } from './components/Header'

export const Companies = () => {
  const loadingStatus = useAppSelector(selectCompanyListLoading)

  Console.danger('loadingStatus: ', loadingStatus)

  return (
    <div className="page-container">
      <div className="shadow-container">
        <Header />
        {loadingStatus === 'loading' ? (
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Loading...
          </h1>
        ) : loadingStatus === 'idle' ? (
          <CompanyList />
        ) : (
          <h1>Error</h1>
        )}
      </div>
    </div>
  )
}
