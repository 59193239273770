import React from 'react'
import './Modal.scss'

export class Confirm extends React.Component<
  {},
  {
    isOpen: boolean
    template: null | JSX.Element
  }
> {
  static _ref: any = null

  static setRef(ref: unknown): void {
    Confirm._ref = ref
  }

  static openConfirm(template: null | JSX.Element) {
    Confirm._ref.openConfirm(template)
  }

  static closeConfirm() {
    Confirm._ref.closeConfirm()
  }
  constructor(props: any) {
    super(props)
    this.state = {
      isOpen: false,
      template: null,
    }
    this.openConfirm = this.openConfirm.bind(this)
  }

  openConfirm(template: null | JSX.Element) {
    this.setState({
      isOpen: true,
      template,
    })
  }

  closeConfirm() {
    this.setState({ isOpen: false })
  }

  render() {
    const { isOpen } = this.state

    return isOpen ? (
      <div className="modal-overlay">
        <div className="modal">{this.state.template}</div>
      </div>
    ) : null
  }
}
