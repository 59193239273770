import React, { FC } from 'react'
import { useFormik } from 'formik'
import { FormField } from '../Companies/components/FormField'
import { Button } from '../../components/Button/Button'
import { useAuthContext } from '../../Provider/AuthProvider'

export type authType = {
  login: string
  password: string
}

export const AuthPage: FC = () => {
  const { authRequest } = useAuthContext()

  const initialValues: authType = {
    login: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    // validationSchema: ,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log('submitted form', values)
      authRequest(values)
    },
  })

  return (
    <div className="shadow-container auth">
      <h1 style={{ marginBottom: 0 }}>Вхід</h1>
      <p className="body-text-2_d">Будь ласка, авторизуйтесь</p>
      <form
        style={{ width: 330 }}
        onSubmit={formik.handleSubmit}
        // className={UtilsStyles['form-container']}
        method="post"
      >
        <FormField
          isEdit={true}
          // error={errors[someId]?.longitude}
          //@ts-ignore
          formik={formik}
          valueKey={formik.values.login}
          tag="input"
          title="Логін"
          name="login"
          type="email"
        />
        <FormField
          isEdit={true}
          // error={errors[someId]?.longitude}
          //@ts-ignore
          formik={formik}
          valueKey={formik.values.password}
          tag="input"
          title="Пароль"
          name="password"
          type="password"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            text="Увійти"
            onClick={formik.submitForm}
            // onClick={async () =>
            //   await AuthService().authRequest({
            //     login: 'asdfsd',
            //     password: 'fsdf',
            //   })
            // }
          />
          {/* <Button text="Увійти" onClick={formik.submitForm} /> */}
        </div>
      </form>
    </div>
  )
}
