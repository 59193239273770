import React, { FC, useCallback, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import styles from '../styles/Documents.module.scss'
import Cloud from '../../../assets/img/cloud.svg'
import PdfIcon from '../../../assets/img/pdfIcon.svg'
type Props = {
  documentList: {
    id: string
    file: File
  }[]
  setDocumentList: (c: any) => void
}

export const Documents: FC<Props> = ({ documentList, setDocumentList }) => {
  // const [
  //   documentList,
  //   setDocumentList,
  // ] = useState<
  //   {
  //     id: string
  //     file: File
  //   }[]
  // >([])
  const labelRef = useRef<HTMLLabelElement>(null)

  console.log('documentList: ', documentList)

  const dragOver = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault()
  }

  const dragEnter = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault()
  }

  const dragLeave = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault()
  }

  const updateDocumentList = useCallback(
    (files: FileList) => {
      let list = [...documentList]
      for (let file of files) {
        if (file.type === 'application/pdf') {
          list.push({
            id: uuidv4(),
            file,
          })
        } else {
          alert('available type PDF only')
        }
      }

      setDocumentList(list)
    },
    [documentList]
  )

  const addFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const files = e.target.files
    if (files) {
      updateDocumentList(files)
    }
  }

  const fileDrop = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    updateDocumentList(files)
  }

  const deleteItem = (id: string) => {
    setDocumentList(documentList.filter((file) => file.id !== id))
  }

  return (
    <div>
      <p className="input__label-title">Вкладення</p>

      <input
        onChange={addFile}
        type="file"
        id="uploadDocuments"
        hidden
        accept="application/pdf"
      />

      <label htmlFor="uploadDocuments" ref={labelRef}>
        <div
          className={styles['drag-documents']}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
        >
          <img
            src={Cloud}
            alt="cloud"
            className={styles['drag-documents__image']}
          />
          <p className="input__label-title">Перетягніть сюди файл або </p>
          <p className={styles['drag-documents-link']}>
            завантажте його зі свого носія
          </p>
        </div>
      </label>

      {documentList.map((file) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <img
              src={PdfIcon}
              alt="pdfIcon"
              style={{
                paddingRight: 10,
              }}
            />
            <p className="body-text-3">{file.file.name}</p>
            <button
              type="button"
              onClick={() => deleteItem(file.id)}
              className={styles['cross-button']}
            >
              <div className={styles['cross-button_item']} />
              <div className={styles['cross-button_item-second']} />
            </button>
          </div>
        )
      })}
    </div>
  )
}
